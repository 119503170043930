import { doc, getDoc, getDocs, collection, query, orderBy } from 'firebase/firestore';
import { useState, useEffect } from 'react';
import db from '../../firebase';

const useData = (collectionName, id = null) => {
    const [data, setData] = useState(id ? null : []);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(null);

            try {
              if (id) {
                  // Fetch single document
                  const docRef = doc(db, collectionName, id);
                  const docSnap = await getDoc(docRef);
                  if (docSnap.exists()) {
                      setData({ id: docSnap.id, ...docSnap.data() });
                  } else {
                      throw new Error('Document not found');
                  }
              } else {
                  // Fetch all documents
                  const snapshot = await getDocs(collection(db, collectionName));
                  const docs = snapshot.docs.map((doc) => ({
                      id: doc.id,
                      ...doc.data(),
                  }));
                  setData(docs);
              }
            } catch (err) {
                console.error(`Error fetching ${collectionName}:, err`);
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [collectionName, id]);

    return { data, loading, error };
};

export default useData;